import React from 'react'
import '../styles/home.css'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import Josh from '../assets/Josh.png'
import Star from '../assets/StarOrange.png'
import Marquee from 'react-fast-marquee'

const Home = () => {
  return (
    <div className='home-wrapper'>
        <Image src={Josh} className='image-home' />
        <div className='description'>
          <span className='tab-header' style={{color: 'rgb(40,40,40)'}}>LA based</span>
          <div className='description-sub'>
            <span className='tab-header attribute'>Developer</span>
            <span className='tab-header attribute'>Designer</span>
            <span className='tab-header attribute'>Photographer</span>
            <span className='tab-header attribute'>Cat Lover</span>
            <span className='tab-header attribute'>Creative</span>
          </div>
        </div>
        <Marquee className='Margquee-style' continous={true} gradient={false} speed={100}>
            <span className='text-slide' style={{marginLeft: 30}}>React Native</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
            <span className='text-slide' style={{marginLeft: 30}}>Visual Design</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
            <span className='text-slide' style={{marginLeft: 30}}>ReactJS</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
            <span className='text-slide' style={{marginLeft: 30}}>Photography</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
            <span className='text-slide' style={{marginLeft: 30}}>Graphic Design</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
            <span className='text-slide' style={{marginLeft: 30}}>App Development</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
            <span className='text-slide' style={{marginLeft: 30}}>Photoshop</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
            <span className='text-slide' style={{marginLeft: 30}}>Video Editing</span>
            <span className='text-slide' style={{marginLeft: 30}}>~</span>
        </Marquee >
    </div>
  )
}

export default Home