import React from 'react'
import '../styles/cafe.css'
import { Col, Container, Image, Row } from 'react-bootstrap'
import brand from '../assets/cafe/BrandingSheet.png'
import menu from '../assets/cafe/MenuNoBottom.png'
import light from '../assets/cafe/LightRoast.png'
import dark from '../assets/cafe/DarkRoast.png'
import shirt from '../assets/cafe/Shirt.png'
import hoodie from '../assets/cafe/Hoodie.png'


const Cafe = () => {
  return (
    <Container className='g-4' style={{display: 'flex', flexDirection: 'column'}}>
            <span className='section-header'>Hellwach*</span>
            <a className='link-card' href="https://cheerful-heliotrope-938551.netlify.app/" target="_blank">
                <span className='socials-title'>Website - Click Here</span>
            </a>
            <h1 className='section-headers'  style={{marginTop: 20, marginBottom: 20}}>Brand Description</h1>
            <p className='section-text'>
                Cafe Hellwach is a fictional coffee shop located in the heart of berlin. It is a modern, yet playful location that prides itself for a minimalist menu.
            </p>
            <h1 className='section-headers'  style={{marginTop: 20, marginBottom: 20}}>Brand Name</h1>
            <p className='section-text'>
                “Hellwach” means wide awake in German, which fits the vibe of being a coffee shop and providing a space for creatives to thrive.
            </p>
            <h1 className='section-headers'  style={{marginTop: 20, marginBottom: 20}}>Brand Colors</h1>
            <p className='section-text'>
                For this brand I was inspired by a traditional cup of coffee, a white cup with brown coffee.
                To maintain a color scheme that is easy on the eyes I went with a mocha color, and used white as a contrast color.
                <br></br><b>Mocha: #594A42</b>
            </p>
          <h1 className='section-headers'  style={{marginTop: 20, marginBottom: 20}}>Brand Documents</h1>
          <Row className='g-4'>
            <Col sm={6}>
                <Image src={brand} style={{width: '100%'}}/>
            </Col>
            <Col sm={6}>
                <Image src={menu} style={{width: '100%'}}/>
            </Col>
          </Row>
          <h1 className='section-headers' style={{marginTop: 20, marginBottom: 20}}>Brand Mockups</h1>
          <Row style={{backgroundColor: '#fff'}}>
            <Col sm={3}>
                <Image src={light} style={{width: '100%'}}/>
            </Col>
            <Col sm={3}>
                <Image src={dark} style={{width: '100%'}}/>
            </Col>
            <Col sm={3}>
                <Image src={shirt} style={{width: '100%'}}/>
            </Col>
            <Col sm={3}>
                <Image src={hoodie} style={{width: '100%'}}/>
            </Col>
          </Row>
    </Container>
  )
}

export default Cafe