import React from 'react'
import '../styles/portfolio.css'
import ProjectCard from '../components/ProjectCard'
import Marquee from 'react-fast-marquee'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Mute from '../assets/MuteCover.jpg'
import Sense from '../assets/SensePoster.jpg'
import Melt from '../assets/MeltPoster.jpg'
import Symbolism from '../assets/SymbolismJPG.jpg'

const Portfolio = () => {
  return (
    <div>
        <span className='section-header'>Portfolio*</span>
        <ProjectCard navPath={"/cafe-hellwach"} title={'Cafe Hellwach'} year={'2023'} description={"Web Development, Branding, Photos, and Illustrations"}/>
        <ProjectCard url={"https://jolly-rabanadas-a5d0cd.netlify.app"} title={'Popp Record Shop'} year={'2023'} description={"Web Design + Development, Photos, Illustrations"}/>
        <ProjectCard url={"https://www.hitecnoise.com"} title={'Hi-Tec Noise'} year={'2022'} description={"Web Design + Development"}/>
        <Container style={{gap: 10, display: 'flex', flexDirection: 'column'}}>
          <Row className='g-2'>
            <Col sm={6}>
              <Image src={Sense} style={{width: '100%'}}/>
            </Col>
            <Col sm={6}>
              <Image src={Mute} style={{width: '100%'}}/>
            </Col>
          </Row>
          <Row className='g-2'>
            <Col sm={6}>
              <Image src={Melt} style={{width: '100%'}}/>
            </Col>
            <Col sm={6}>
              <Image src={Symbolism} style={{width: '100%'}}/>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default Portfolio