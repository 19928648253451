import React from 'react'
import '../styles/footer.css'

const Footer = () => {
  return (
    <div className='footer-wrapper'>
        <h3>Joshua Popp © 2023</h3>
    </div>
  )
}

export default Footer