import './App.css';
import { Button, Container } from 'react-bootstrap';
import NavContainer from './components/NavContainer';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import SpashScreen from './components/SpashScreen';
import Home from './screens/Home';
import Footer from './components/Footer';
import Portfolio from './screens/Portfolio';
import Contact from './screens/Contact';
import Cafe from './screens/Cafe';

function App() {
  return (
    <div className='App' style={{backgroundColor: "#dcdcdc", minHeight: '100vh',}}>
      <SpashScreen />
      <Router> 
        <NavContainer></NavContainer>
        <Container style={{minHeight: '100vh'}}>
          <Routes>
              /* <Route path="/" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/cafe-hellwach" element={<Cafe />} />
          </Routes>
        </Container>
      </Router>
      <div style={{paddingBottom: 50}}></div>
      <Footer></Footer>
    </div>
  );
}

export default App;
