import React from 'react'
import '../styles/contact.css'
import Marquee from 'react-fast-marquee'


const Contact = () => {
  return (
    <div className='contact-container'>
      <span className='section-header'>Email*</span>
      <a className='email-title'>
        <span className='email-title'>joshpoppdev@gmail.com</span>
      </a>
      <div className='gap-value'></div>
      <span className='section-header'>Social Links*</span>
      <div className='socials-container'>
        <a className='social-card' href="https://www.instagram.com/joshpopp.design/" target="_blank">
          <span className='socials-title'>Instagram</span>
        </a>
        <a className='social-card' href="https://github.com/joshuapopp17" target="_blank">
          <span className='socials-title'>Github</span>
        </a>
        <a className='social-card' href="https://www.linkedin.com/in/joshua-popp-352486240/" target="_blank">
          <span className='socials-title'>Linkedin</span>
        </a>
      </div>
    </div>
  )
}

export default Contact