import React from 'react'
import '../styles/splashscreen.css'
import { Image } from 'react-bootstrap'
import Star from '../assets/StarOrange.png'

const SpashScreen = () => {
  return (
    <div className='screen-container fadeOut exist'>
        <Image src={Star} className='image-logo' />
        <p className='text'>Joshua Popp</p>
    </div>
  )
}

export default SpashScreen