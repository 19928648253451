import React from 'react'
import '../styles/portfolio.css'
import { useNavigate } from 'react-router-dom'

const ProjectCard = ({title, year, description, url, navPath}) => {
  const navigate = useNavigate()

  return (
    <a className='main-card-wrapper' href={url} onClick={() => navigate(navPath)}>
      <div className='card-wrapper'>
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <span className='card-header'>{title}</span>
              <span className='card-header'>{year}</span>
          </div>
          <span className='card-description'>{description}</span>
      </div>
    </a>
  )

}

export default ProjectCard